.MuiAvatar-img {
    border: none;
}

.leaflet-tile {
    border: none;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    border: none;
}